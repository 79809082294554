import ButtonEvent from "~/components/ui/buttons/ButtonEvent";
import { DataSourceTypes } from "../../dtos/DataSourceType";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import DataSourceIcons from "../icons/DataSourceIcons";
import FeedbackForm from "~/modules/helpDesk/components/FeedbackForm";
import Modal from "~/components/ui/modals/Modal";
import { useState } from "react";
import StarsIconFilled from "~/components/ui/icons/StarsIconFilled";
import { Button } from "~/components/ui/button";
import SimpleBadge from "~/components/ui/badges/SimpleBadge";
import { Colors } from "~/application/enums/shared/Colors";
import SparklesIconFilled from "~/components/ui/icons/SparklesIconFilled";
import { useSearchParams } from "@remix-run/react";

interface Props {
  withDescriptions?: boolean;
  className?: string;
  withFeedback?: boolean;
  showPremium?: boolean;
}
export default function AllDemos({
  withDescriptions = true,
  className = "grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4",
  withFeedback,
  showPremium,
}: Props) {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const screenshotMode = searchParams.get("screenshotMode") === "true";
  return (
    <div className="space-y-2">
      {/* {!screenshotMode && (
        <div className="flex items-center justify-between space-x-2">
          <div className="text-2xl font-semibold">{i18n.language === "es" ? <span>Crea un chatbot con...</span> : <span>Create a chatbot from...</span>}</div>
          <div className="hidden md:block">
            <Button variant="ghost" type="button" onClick={() => setIsOpen(!isOpen)}>
              <div className="flex items-center space-x-2">
                <StarsIconFilled className="h-5 w-5 p-0.5" />
                <div className="title-font truncate text-sm font-bold">Request Source</div>
              </div>
            </Button>
          </div>
        </div>
      )} */}

      <div className={clsx(className)}>
        {DataSourceTypes.filter((f) => f.demoSlug).map((type) => {
          const dataSourceType = DataSourceTypes.find((f) => f.value === type.value);
          if (!dataSourceType) {
            return null;
          }
          if (screenshotMode) {
            type.disabled = false;
            type.underConstruction = false;
          }
          return (
            <div
              key={type.value}
              className={clsx("border-border rounded-lg border-2", type.underConstruction ? " bg-secondary opacity-50" : " bg-background hover:bg-secondary")}
            >
              <ButtonEvent
                className={clsx("block h-full w-full select-none", type.underConstruction ? "cursor-not-allowed" : "cursor-pointer")}
                disabled={type.underConstruction}
                event={{
                  action: "click",
                  category: "all-demos",
                  label: dataSourceType.name,
                  value: dataSourceType.value,
                }}
                to={`/chat-with/${type.demoSlug}`}
              >
                <div className="p-4">
                  <div>
                    <div className="flex items-center justify-between space-x-2">
                      <div className="flex items-center space-x-3 truncate">
                        <DataSourceIcons type={type.value} className={clsx("flex-shrink-0", screenshotMode ? "h-10 w-10" : "h-6 w-6")} />
                        <div className={clsx("title-font truncate", screenshotMode ? "text-xl font-bold" : "text-lg font-bold")}>
                          {t("datalang.dataSourceTypes." + type.value)}
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        {type.premium && showPremium && (
                          <div>
                            <SparklesIconFilled className="h-6 w-6 p-0.5 text-yellow-400" />
                            {/* <SimpleBadge title="Premium" color={Colors.YELLOW} /> */}
                          </div>
                        )}
                        {type.underConstruction && false && (
                          <div className="ml-2 truncate text-base font-semibold uppercase">
                            {/* <span className="hidden truncate md:block">{t("shared.underConstruction")} 🚧</span>
                            <span className="truncate md:hidden">🚧</span> */}
                            🚧
                          </div>
                        )}
                      </div>
                    </div>
                    {withDescriptions && (
                      <p className="mt-2 text-base text-gray-500 dark:text-gray-400">{t("datalang.dataSourceTypeDescriptions." + type.value)}</p>
                    )}
                  </div>
                </div>
              </ButtonEvent>
            </div>
          );
        })}
      </div>

      <Modal open={isOpen} setOpen={setIsOpen} size="sm">
        <FeedbackForm type={"anonymous"} onClose={() => setIsOpen(false)} />
      </Modal>
    </div>
  );
}
